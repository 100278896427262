import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';
import {TranslateModule} from '@ngx-translate/core';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {InputLabelComponent} from '@shared/components/forms/components/input-label/input-label.component';
import {BasicFormInputComponent} from '@shared/components/forms/basic/basic-form-input.component';

@Component({
  standalone: true,
  selector: 'ups-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskPipe,
    NgxMaskDirective,
    KeenIconComponent,
    TranslateModule,
    FormsModule,
    InputLabelComponent,
    InputTextModule,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class InputComponent extends BasicFormInputComponent {
  @Input() type: 'text' | 'number' | 'password' | 'email' | 'date' | 'search' | 'color' = 'text';
  @Input() svgIcon = '';
  @Input() readonly = false;
  @Input() mask = '';
  @Input() prefix = '';
  @Input() allowNegativeNumbers = false;
  @Input() thousandSeparator = '';
  @Input() max = Infinity;
  @Input() min = -Infinity;
  @Input() patterns: any;
  @Input() dropSpecialCharacters: boolean = false;
  @Input() specialCharacters: string[];
  @Input() showMaskTyped: boolean = false;
  @Input() showErrorContent: boolean = true;

  eyeIconToggle = false;
  protected readonly Validators = Validators;

  togglePasswordType() {
    this.eyeIconToggle = !this.eyeIconToggle;
    if (this.type == 'password') {
      this.type = 'text';
    } else if (this.type == 'text') {
      this.type = 'password';
    }
  }
}
